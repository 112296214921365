<template>
  <v-dialog v-model="visible"
    max-width="500"
    persistent>
    <v-card>
      <v-card-title class="flex-nowrap">
        <span class="d-inline-block text-truncate text-no-wrap">
          {{ title }}
        </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close('cancel')" v-if="cancelBtn">
          <v-icon color="error">{{ colIcons.close }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-alert :type="type"  border="left" text v-if="content">{{ content }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="cancelBtn" text color="error" @click="close('cancel')">{{ cancelText }}</v-btn>
        <v-btn text color="primary" @click="close('ok')">{{ okText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mandatoryIcons } from '@/utilities'

export default {
  name: 'rhb-prompt',

  data: () => ({
    colIcons: Object.freeze(mandatoryIcons),
    visible: false,
    ok: null,
    cancel: null,

    options: {
      type: 'info',
      title: null,
      content: null,
      okText: null,
      cancelText: null,
      cancelBtn: true
    }
  }),

  computed: {
    type () {
      return this.options.type
    },
    title () {
      return this.options.title
    },
    content () {
      return this.options.content
    },
    okText () {
      return this.options.okText
    },
    cancelText () {
      return this.options.cancelText
    },
    cancelBtn () {
      return this.options.cancelBtn
    }
  },

  methods: {
    async show (options) {
      this.options = Object.assign(this.options, options)

      this.visible = true

      return new Promise((resolve, reject) => {
        this.ok = resolve
        this.cancel = reject
      })
    },
    close (callback) {
      this.visible = false

      this[callback]()
    }
  }
}
</script>
