<template>
  <country-flag :country="language" :size="size" />
</template>

<script>
export default {
  name: 'rhb-flag',

  props: {
    size: {
      type: String,
      default: 'small'
    },
    country: {
      type: String,
      required: true
    }
  },

  computed: {
    language () {
      switch (this.country.toLowerCase()) {
        case 'en': return 'gb'
        case 'ja': return 'jp'
        default: return this.country || ''
      }
    }
  }
}
</script>
