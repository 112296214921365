function getDefaultValue (variable) {
  switch (variable) {
    case 'language': {
      const lang = localStorage.getItem('currentLanguage')

      if (lang) {
        return JSON.parse(lang)
      } else {
        return {
          title: 'Romana/Romania',
          countryCode: 'RO',
          languageCode: 'ro'
        }
      }
    }
    case 'theme': {
      const theme = localStorage.getItem('theme')
      if (theme) return theme
      return 'light'
    }
  }
}

const mandatoryIcons = {
  // misc

  excel: 'mdi-file-excel-outline',

  columnNumber: 'mdi-table-column-width',
  columnTitle: 'mdi-page-layout-header',

  slug: 'mdi-link-variant',
  url: 'mdi-link-variant',

  null: 'mdi-minus',

  'typeshort text': 'mdi-text-short',
  'typelong text': 'mdi-text-long',
  typeboolean: 'mdi-checkbox-marked-circle-outline',
  typeinteger: 'mdi-numeric',
  typedecimal: 'mdi-decimal',
  typedate: 'mdi-calendar',
  typetime: 'mdi-clock',
  typedocument: 'mdi-file-document',
  typeimage: 'mdi-file-image',
  'typeimage gallery': 'mdi-image-multiple',
  'typedocument gallery': 'mdi-file-multiple',
  typehtml: 'mdi-language-html5',

  generateStrongPassword: 'mdi-key-variant',
  username: 'mdi-account',
  email: 'mdi-email',
  password: 'mdi-lock',
  admin: 'mdi-account-star',
  user: 'mdi-account',
  light: 'mdi-white-balance-sunny',
  dark: 'mdi-weather-night',
  right: 'mdi-chevron-right',
  menu: 'mdi-menu',
  log: 'mdi-bell',
  login: 'mdi-login',
  logout: 'mdi-logout',
  close: 'mdi-close',
  visible: 'mdi-eye',
  hidden: 'mdi-eye-off',
  filter: 'mdi-filter',
  page: 'mdi-page-next',
  clearAll: 'mdi-delete-sweep',
  delete: 'mdi-delete',
  search: 'mdi-magnify',
  earth: 'mdi-earth',
  download: 'mdi-download',
  information: 'mdi-information',
  dots: 'mdi-dots-vertical',
  refresh: 'mdi-refresh',
  settings: 'mdi-cog',
  check: 'mdi-check',
  file: 'mdi-file',
  logAll: 'mdi-text-box',
  logSuccess: 'mdi-check',
  logInfo: 'mdi-information-outline',
  logWarning: 'mdi-alert-circle-outline',
  logError: 'mdi-close',
  mapSearch: 'mdi-map-search',
  grouping: 'mdi-circle-multiple-outline',
  true: 'mdi-check',
  false: 'mdi-close',
  edit: 'mdi-pencil',
  import: 'mdi-file-excel',
  dictionaryKey: 'mdi-file-excel',
  restore: 'mdi-restore',
  nuke: 'mdi-nuke',
  add: 'mdi-plus',
  selectedIcon: 'mdi-map-marker-circle',
  alert: 'mdi-alert',
  percent: 'mdi-percent',
  paperpclip: 'mdi-paperclip',
  upload: 'mdi-upload',
  total: 'mdi-sigma',
  nowrap: 'mdi-wrap-disabled',
  wrap: 'mdi-wrap',

  // menu
  home: 'mdi-home',
  apls: 'mdi-map',
  categories: 'mdi-shape',
  statuses: 'mdi-filter',
  properties: 'mdi-text-short',
  propertygroups: 'mdi-text-box-outline',
  markers: 'mdi-map-marker-multiple',
  marker: 'mdi-map-marker',
  users: 'mdi-account-group',
  usertypes: 'mdi-account-convert',
  dictionary: 'mdi-book-alphabet',
  languages: 'mdi-sign-language',

  // markers
  map: 'mdi-map',
  unique_apl: 'mdi-map',
  unique: 'mdi-passport',
  status: 'mdi-filter',
  category: 'mdi-shape',
  availability: 'mdi-eye',
  address: 'mdi-routes',
  name: 'mdi-format-title',
  lat: 'mdi-latitude',
  lng: 'mdi-longitude',

  // apls
  fill: 'mdi-format-color-fill',
  borderPoly: 'mdi-vector-polygon',
  border: 'mdi-border-all-variant',
  scale: 'mdi-resize',
  image: 'mdi-image',
  county: 'mdi-map-outline',
  web: 'mdi-web',

  // categories
  code: 'mdi-file-excel',
  // statuses

  // properties
  info: 'mdi-information-outline',
  order: 'mdi-sort-numeric-ascending',
  energy: 'mdi-lightning-bolt-outline',
  type: 'mdi-format-paint',
  column: 'mdi-table-column',

  // property groups
  propertygroup: 'mdi-text-box-outline',
  property: 'text-short',
  expand: 'mdi-arrow-expand-all'
}

export { getDefaultValue, mandatoryIcons }
