import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import hu from 'vuetify/lib/locale/hu'

Vuetify.config.silent = true

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0fb693',
        secondary: '#79848c',
        accent: '#247dbe',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#0fb693',
        secondary: '#79848c',
        accent: '#247dbe',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    locales: { hu },
    current: 'hu'
  }
})
