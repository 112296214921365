import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import GmapVue, { components } from 'gmap-vue'
import GmapVue from 'gmap-vue'
import CountryFlag from 'vue-country-flag'
import { getDefaultValue } from './utilities'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

// Vue.component('gmap-cluster', components.Cluster)
Vue.component('country-flag', CountryFlag)

Vue.use(GmapVue, {
  load: {
    // key: process.env.NODE_ENV === 'production' ? 'AIzaSyChqPhP8whqcJTyUVU1bULDW67DRDIjWFc' : 'AIzaSyBjMBDEKPU-DE7ZGQA8sYcD10Trmbh6zEI',
    key: process.env.NODE_ENV === 'production' ? 'AIzaSyAFxa2Ks6PKYzwePO0l5Si8Z0BNir9u1DM' : 'AIzaSyBjMBDEKPU-DE7ZGQA8sYcD10Trmbh6zEI',
    libraries: 'geometry,visualization',
    region: getDefaultValue('language').countryCode,
    language: getDefaultValue('language').languageCode,
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
