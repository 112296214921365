import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/'

import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/APLs',
    name: 'APLs',
    component: () => import(/* webpackChunkName: "apls" */ '../views/APLs.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/Statuses',
    name: 'Statuses',
    component: () => import(/* webpackChunkName: "statuses" */ '../views/Statuses.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/Categories',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/Markers',
    name: 'Markers',
    component: () => import(/* webpackChunkName: "markers" */ '../views/Markers.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/Properties',
    name: 'Properties',
    component: () => import(/* webpackChunkName: "properties" */ '../views/Properties.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/PropertyGroups',
    name: 'PropertyGroups',
    component: () => import(/* webpackChunkName: "propertygroups" */ '../views/PropertyGroups.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/UserTypes',
    name: 'UserTypes',
    component: () => import(/* webpackChunkName: "usertypes" */ '../views/UserTypes.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/Users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "user" */ '../views/Users.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/APLMap/:id',
    name: 'APLMap',
    component: () => import(/* webpackChunkName: "aplmap" */ '../views/APLMap.vue'),
    meta: {}
  },
  {
    path: '/Dictionary',
    name: 'Dictionary',
    component: () => import(/* webpackChunkName: "dictionary" */ '../views/Dictionary.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '/Language',
    name: 'Language',
    component: () => import(/* webpackChunkName: "language" */ '../views/Language.vue'),
    meta: { auth: 'admin' }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const auth = to.meta.auth || false
  let isAllowed = store.getters.user ? store.getters.user[auth] : false

  if (auth) {
    if (isAllowed) next()
    else {
      const loggedIn = await store.dispatch('reAuth')
      isAllowed = store.getters.user ? store.getters.user[auth] : false

      if (loggedIn && isAllowed) next()
      else next({ name: 'Home' })
    }
  } else {
    next()
  }
})

export default router
